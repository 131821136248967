<template>
  <v-card class="ma-4">
    <v-toolbar :color="selectedItems.length ? 'grey darken-4' : '#335D6E'" dark
               elevation="1">

      <v-toolbar-title> {{ selectedItems.length ? `Selected (${selectedItems.length})  rows` : title }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <v-text-field
          v-if="!selectedItems.length"
          v-model="search"
          append-icon="mdi-magnify"
          clearable
          filled
          hide-details
          label="Search about something"
          single-line
      ></v-text-field>

      <v-btn
          v-if="selectedItems.length"
          color="grey darken-2"
          dark
          @click.stop="restoreItems"
      >Restore
      </v-btn>

      <v-dialog v-if="!selectedItems.length" v-model="dialogAddItem" fullscreen
                hide-overlay
                transition="dialog-bottom-transition">
        <v-card v-if="dialogAddItem">
          <v-toolbar
              color="blue-grey"
              dark
          >
            <v-btn
                dark
                icon
                @click="dialogAddItem = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer></v-spacer>

            <template v-slot:extension>
              <v-tabs
                  v-model="tab"
                  center-active
                  centered

              >
                <v-tabs-slider color="yellow"></v-tabs-slider>

                <v-tab
                    v-for="(tabItem) in tabItems"
                    :key="tabItem"
                    @click="tab=tabItem"
                >

                  <div>
                    {{ tabItem }}
                  </div>


                </v-tab>
              </v-tabs>
            </template>
          </v-toolbar>


          <v-alert
              v-if="Object.keys(errors).length>0"
              dense
              text
              type="error"
          >
            <h4 class="subtitle">Correct the following errors:</h4>
            <ul>
              <li
                  v-for="(error, index) in errors"
                  :key="index"
              >{{ error.message || error }}
              </li>
            </ul>
          </v-alert>


          <v-card-text>
            <v-container>
              <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="tabItem in tabItems"
                    :key="tabItem"
                >
                  <v-form
                      v-if="Number(tab)===0"
                      ref="form"
                      v-model="valid"
                      class="mt-4"
                      lazy-validation>
                    <v-alert
                        border="bottom"
                        color="green darken-1"
                        dark
                    >
                      you can see the link for this quote
                      <span class="yellow--text text--darken-3  " style="cursor: pointer" @click="copyURL ">( click here to past ) </span>

                    </v-alert>
                    <v-row>

                      <v-col cols="12" lg="4" md="4" sm="12">
                        <v-text-field v-model="customerName" label="Customer Name" outlined
                                      readonly type="text"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="4" md="4" sm="12">
                        <v-text-field v-model="phoneNumber" label="Customer Phone" outlined
                                      readonly type="text"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="4" md="4" sm="12">
                        <v-text-field v-model="email" label="Customer Email" outlined
                                      readonly type="text"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4" md="4" sm="12">
                        <v-text-field v-model="postcode" label="Postcode" outlined
                                      readonly type="text"
                        ></v-text-field>
                      </v-col>


                      <v-col cols="12" lg="4" md="4" sm="12">
                        <v-text-field v-model="createdAt" label="Quote Date" outlined
                                      readonly type="text"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4" md="4" sm="12">
                        <v-text-field v-model="expireAt" label="Quote Expire Date" outlined
                                      readonly type="text"
                        ></v-text-field>
                      </v-col>


                      <v-col cols="12" lg="6" md="6" sm="12">
                        <v-text-field v-model="productName" :rules="postcodeRules" label="Product name" outlined
                                      readonly required></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="2" md="2" sm="12">

                        <v-text-field :rules="[v => !!v || 'Amount is required']" label="Amount" max="1" min="1" outlined
                                      readonly
                                      type="number" value="1"

                        ></v-text-field>

                      </v-col>
                      <v-col cols="12" lg="2" md="2" sm="12">
                        <v-text-field v-model="productPrice" label="Price" min="0" outlined
                                      readonly type="number"
                        ></v-text-field>

                      </v-col>
                      <v-col cols="12" lg="2" md="2" sm="12">
                        <v-text-field v-model="productTotal" :rules="[v => !!v || 'Total is required']" label="Total"
                                      min="0"
                                      outlined
                                      readonly type="number"
                        ></v-text-field>
                      </v-col>


                      <v-col class="d-flex flex-wrap justify-end" cols="12">


                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field label="Install Price" min="0" outlined
                                        readonly type="number" value="0"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="postcodePrice" label="postcode Price" min="0" outlined
                                        readonly type="number"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="totalTax" label="Tax Percent" min="0" outlined
                                        readonly type="number"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="totalPrices" label="Total Prices" min="0" outlined
                                        readonly type="number"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field label="Discount Price" min="0" outlined readonly
                                        type="number" value="0"
                          ></v-text-field>
                        </v-col>


                        <v-col cols="12" lg="3" md="3" sm="6">
                          <v-text-field v-model="totalFinalPrice" label="Final Price" min="0" outlined
                                        readonly type="number"
                          ></v-text-field>
                        </v-col>

                      </v-col>


                    </v-row>
                  </v-form>

                  <v-form
                      v-show="Number(tab)===1"
                      ref="form"
                      v-model="valid"
                      class="mt-4"
                      lazy-validation>
                    <v-row>
                      <v-col v-for="(parent,index) in questions.filter(c => c.parentID === 0 && c.isActive===true)" :key="index" cols="12"
                             lg="4"
                             md="6"
                             sm="12">


                        <div class="black--text font-weight-bold mb-2">{{ parent.name }}</div>

                        <v-select
                            v-model="questionAnswers[parent.id]"
                            :items="questions.filter(c => c.parentID === parent.id && c.isActive===true)"
                            hide-details
                            item-text="name"
                            item-value="id"
                            label="Select from menu"
                            outlined
                            readonly
                            return-object
                        ></v-select>


                      </v-col>


                    </v-row>
                  </v-form>

                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-card-text>

        </v-card>
      </v-dialog>

      <v-btn
          v-else
          class="mx-11"
          color="grey darken-2"
          dark
          @click.stop="showDialogDelete"
      >Delete
      </v-btn>
      <v-btn
          v-if="selectedItems.length"
          icon
          @click="selectedItems = []"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-menu
          v-else
          :close-on-content-click="false"
          :nudge-width="250"
          offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                    v-model="isShowDeleted"
                    color="indigo"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title class="ml-4">Show deleted data</v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-action>
                <v-switch
                    v-model="isForceDeleted"
                    color="indigo"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title class="ml-4">Delete data forever</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar>


    <v-card-text>
      <v-dialog
          v-model="dialogDelete"
          max-width="425"
          persistent
      >
        <v-card>
          <v-card-title>
            Delete group of data ({{ selectedItems.length ? selectedItems.length : 1 }}) rows
          </v-card-title>

          <v-card-text class=" ">
            Are you sure for continue deleting these data ?
          </v-card-text>

          <v-card-actions>
            <v-checkbox
                v-model="isForceDeletedRow"
                :disabled=disableChangeDelete
                :ripple=false
                class="ma-2 red--text "
                color="red"
                hide-details
            >
              <template v-slot:label>
                <div class="red--text body-2">
                  Delete data forever
                </div>
              </template>
            </v-checkbox>

            <v-spacer></v-spacer>

            <v-btn
                :disabled="buttonLoading"
                class="body-2"
                color="primary"
                text
                @click="hideDialogDelete()"
            >
              Reject
            </v-btn>

            <v-btn
                :disabled="buttonLoading"
                class="body-2"
                color="red darken-2"
                text
                @click="destroyItems"
            >
              Process
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          v-if="!dialogDelete && !dialogAddItem"
          v-model="buttonLoading"
          hide-overlay
          persistent
          width="300"
      >
        <v-card
            color="indigo"
            dark
        >
          <v-card-text>
            <div class="py-4 body-1 white--text text-center">Waiting for process request... please wait</div>

            <v-progress-linear
                class="my-2"
                color="white"
                indeterminate
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-data-table
          v-model="selectedItems"
          :footer-props="{'items-per-page-options': [10, 20, 40, 80, 100]}"
          :headers="headers"
          :item-class="(item)=>item.deletedAt?'deletedRow':null"
          :items="desserts"
          :items-per-page="10"
          :loading="loading"
          :options.sync="options"
          :search="search"
          checkbox-color="'blue'"
          item-key='id'
          show-select
      >


        <template v-slot:header.data-table-select="{props,on}">
          <v-simple-checkbox :ripple=false color="primary" v-bind="props" v-on="on"></v-simple-checkbox>
        </template>
        <template v-slot:item.data-table-select="{isSelected,select}">
          <v-simple-checkbox :ripple=false :value="isSelected" color="primary" @input="select($event)"></v-simple-checkbox>
        </template>
        <template v-slot:item.id="{ item }">
          <v-chip>{{ item.id }}</v-chip>
        </template>
        <template v-slot:item.deletedAt="{ item }">
          <v-icon
              v-if="item.deletedAt"
              class="mr-3"
              color="primary"
              small
              @click="restoreItems(item)"
          >mdi-restore
          </v-icon>


          <v-chip
              color="indigo"
              label
              outlined
              small
              @click="editItem(item)"
          >
            <v-icon small>
              mdi-pencil
            </v-icon>
          </v-chip>


          <v-chip
              :color="item.deletedAt ?'orange':'red accent-4'"
              class="ml-3"
              label
              outlined
              small
              @click="showDialogDelete(item)"
          >
            <v-icon small>
              {{ item.deletedAt ? 'mdi-delete-clock' : ' mdi-delete' }}
            </v-icon>
          </v-chip>

        </template>


        <template v-slot:item.isActive="{ item }">
          <v-icon :color="(item.isActive)?'green':'red'">
            mdi-eye
          </v-icon>
        </template>
        <template v-slot:item.userID="{ item }">
          <v-chip>{{ getUsername(item) }}</v-chip>
        </template>

        <template v-slot:item.expiredAt="{ item }">
          <v-chip dark>{{ getFormattedDate(new Date(item.expiredAt)) }}</v-chip>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <v-chip color="primary" dark outlined>{{ getFormattedDate(new Date(item.createdAt)) }}</v-chip>
        </template>

        <template v-slot:item.type="{ item }">
          <v-chip color="primary" dark>{{ checkType(item.type) }}</v-chip>
        </template>


        <template v-slot:item.AddressOrder.installPostcode="{ item }">
          <v-chip v-if="item.AddressOrder" color="orange" dark>{{ item.AddressOrder?.installPostcode }}</v-chip>
        </template>

        <template v-slot:item.id="{ item }">
          <v-chip color="primary" dark>{{ item.id }}</v-chip>
        </template>

      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {urlProductPrices, urlQuestions, urlQuote, urlQuotes,} from "@/config";
import networks from "@/services/networks";
import moment from 'moment';

export default {
    data: () => ({
      search: null,
      dialogDelete: false,
      disableChangeDelete: false,
      deleteItemIDs: [],
      selectedItems: [],
      valid: true,
      id: 0,
      item: null,
      postcodeRules: [
        v => !!v || 'postcode is required',
      ],
      addressLine1Rules: [
        v => !!v || 'address line 1 is required',
        v => (v && v.length <= 150) || 'address line 1 must be less than 150 characters',
      ],
      addressLine2Rules: [
        v => (!v || v.length <= 150) || 'address line 2 must be less than 150 characters',
      ],

      errors: [],
      buttonLoader: 'buttonLoading',
      buttonLoading: false,
      dialogAddItem: false,

      title: "Customers Quotes",

      options: null,
      desserts: [],
      loading: true,
      headers: [
        {text: '#', value: 'id', align: 'center'},
        {text: 'Customer name', value: 'name', align: 'center'},
        {text: 'Phone number', value: 'phoneNumber', align: 'center'},
        {text: 'Email', value: 'email', align: 'center'},
        {text: 'Inst. Postcode', value: 'subPostcode', align: 'center'},
        {text: 'Expire Date', value: 'expiredAt', align: 'center'},
        {text: 'Quote Date', value: 'createdAt', align: 'center'},
        {text: 'Actions', value: 'deletedAt', sortable: false, align: 'right'},
      ],
      isFormTitleEdit: false,
      totalPrices: 0,
      postcode: null,
      tab: 0,
      tabItems: ['Order Details', 'Questionnaires Details'],

      questions: [],
      questionAnswers: [],

      uuid: null,
      productName: null,
      product: null,
      productPrice: 0,
      productTotal: 0,
      products: [],

      totalTax: 0,
      orderType: 1,
      postcodePrice: 0,

      customerName: null,
      phoneNumber: null,
      email: null,
      expireAt: null,
      createdAt: null,
      ////// Tab Form 2 index
      engineers: [],
      engineer: null,
      dessertsEngineers: [],
      buttonLoadingAssign: false,


    }),

    created() {
      this.getPriceProductsFromApi();
      this.getQuestionsFromApi();
      this.getQuotesFromApi();
    },


    computed: {
      formTitle() {
        return this.isFormTitleEdit ? 'Quote Details' : 'Add new order'
      },
      totalFinalPrice() {
        return this.productTotal + Number(this.postcodePrice);
      },
      isShowDeleted: {
        get() {
          return this.$store.getters.isShowDeleted;
        },
        set(val) {
          this.$store.state.isShowDeleted = val;
          this.getQuotesFromApi();
          return val;
        }


      },
      isForceDeleted: {
        get() {
          return this.$store.getters.isForceDeleted;
        },
        set(val) {
          this.isForceDeletedRow = val;
          this.$store.state.isForceDeleted = val;
          return val;
        }
      },
      isForceDeletedRow: {
        get() {
          return this.$store.getters.isForceDeleted;
        },
        set(val) {
          this.$store.state.isForceDeleted = val;
          return val;
        }
      },
    },

    watch: {

      dialogAddItem() {
        if (!this.dialogAddItem) {
          this.isFormTitleEdit = false;
          this.clear();
        }
      },


    },

    methods: {
      getQuotesFromApi() {
        this.loading = true;
        networks.fetchFromWeb(urlQuotes + this.isShowDeleted)
            .then(response => {
              this.desserts = response.data;
              this.loading = false;
            })
            .catch(error => {
              alert(error);
            });
      },


      getPriceProductsFromApi() {
        this.loading = true;
        networks.fetchFromWeb(urlProductPrices)
            .then(response => {
              this.products = response.data;
              this.loading = false;
            })
            .catch(error => {
              alert(error);
            });
      },
      getQuestionsFromApi() {
        this.loading = true;
        networks.fetchFromWeb(urlQuestions)
            .then(response => {
              this.questions = response.data.slice().reverse();
              this.loading = false;
            })
            .catch(error => {
              alert(error);
            });
      },

      getFormattedDate(date) {
        return moment(date).format("YYYY-MM-DD")
      },

      statusColor(status) {
        switch (status) {
          case 1:
            return "green";
          case 2:
            return "red";
          case 3:
            return "grey";
          case 4:
            return "brown";
          case 5:
            return "purple";
          case 6:
            return "indigo";

          default:
            return "amber";
        }
      },

      async copyURL() {
        try {
          await navigator.clipboard.writeText(this.quoteUrl);
        } catch (err) {
          console.error('Failed to copy text: ', err);
        }
      },

      checkType(type) {
        if (type === 0)
          return "Manual";
        else if (type === 1 || type === 2)
          return "Automatic";
        else return "Quote"
      },

      clear() {
        if (this.$refs.form != undefined && this.$refs.form[0]) {
          this.$refs.form[0].resetValidation();

        }
        if (this.$refs.form != undefined && this.$refs.form[1]) {
          this.$refs.form[1].resetValidation();

        }


        this.itemStatusOrder = null;
        this.id = 0;
        this.tab = 0;
        this.questionAnswers = [];
        this.orderType = 0;
        this.totalPrices = 0;
        this.productPrice = 0;
        this.productTotal = 0;
        this.postcodePrice = 0;
        this.totalTax = 0;
        this.product = null;
        this.valid = true;
        this.errors = [];
        this.buttonLoading = false;
        this.isSameAddress = false;
        this.paymentType = null;
        this.postcode = null;
        this.productName = null;
        this.uuid = null;

      },


      editItem(item) {
        console.log(item);
        this.clear();
        this.item = item;
        this.isFormTitleEdit = true;
        this.id = item.id;
        this.itemStatusOrder = {id: item.status};
        this.installDate = item.installDate;
        this.customerName = item.name;
        this.phoneNumber = item.phoneNumber;
        this.email = item.email;
        this.postcode = item.subPostcode;
        this.createdAt = this.getFormattedDate(item.createdAt);
        this.expireAt = this.getFormattedDate(item.expiredAt);
        this.uuid = item.uuid;

        this.product = this.products.find((e) => e.id == item.productID);
        if (this.product) {
          this.productPrice = Number(this.product.price) - Number(this.product.discount);
          this.productTotal = item.productTotalPrice;
          this.productName = item.Product?.name;
          this.quoteUrl = urlQuote + item.uuid + "/" + this.product.id;
        } else {
          this.quoteUrl = urlQuote + item.uuid;

        }

        this.orderType = item.type;
        this.cancelReason = item.cancelReason;
        if (item.type < 1) {
          this.paymentType = null;
        } else {
          this.paymentType = (item.type === 1) ? "Financial payment" : "Stripe payment";
        }

        this.postcodePrice = item.postcodePrice;

        item.QuoteQuestions.map(data => {
          this.questionAnswers[data.questionID] = {id: data.answerID};
        });

        this.dialogAddItem = true;
      },

      showDialogDelete(item) {
        if (this.selectedItems.length < 1) {
          if (!item.deletedAt || (item.deletedAt && this.isForceDeleted)) {
            this.disableChangeDelete = item.deletedAt;
            this.dialogDelete = true;
            this.deleteItemIDs.push(item.id);
          }
        } else {
          this.dialogDelete = true;
        }

      },
      hideDialogDelete() {
        this.dialogDelete = false;
        this.disableChangeDelete = false;
        this.deleteItemIDs = [];
        this.selectedItems = [];
      },
      destroyItems() {

        this.buttonLoading = true;
        if (this.selectedItems.length > 0) {
          let currentSelected = [];

          if (this.isForceDeletedRow)
            currentSelected = this.selectedItems;
          else
            currentSelected = this.selectedItems.filter((e) => e.deletedAt === false);


          currentSelected.map((e) => this.deleteItemIDs.push(e.id));
        }


        if (this.deleteItemIDs.length < 1) {
          this.hideDialogDelete();
          this.buttonLoading = false
        } else {
          networks.fetchFromWeb(urlQuotes + this.deleteItemIDs + "/" + this.isForceDeletedRow, 3)
              .then(response => {

                if (response.status == 200) {
                  this.hideDialogDelete();
                  alert("Data has been deleted");
                  this.getQuotesFromApi();
                }

              })
              .catch(() => {
                alert("Failed for process delete data");
                this.hideDialogDelete();
              }).finally(() => this.buttonLoading = false);
        }

      },

      restoreItems(item) {
        this.buttonLoading = true;
        if (this.selectedItems.length > 0) {
          let currentSelected = [];
          currentSelected = this.selectedItems.filter((e) => e.deletedAt === true);
          currentSelected.map((e) => this.deleteItemIDs.push(e.id));

        } else {
          this.deleteItemIDs.push(item.id);
        }

        if (this.deleteItemIDs.length < 1) {
          this.hideDialogDelete();
          this.buttonLoading = false;
        } else {

          networks.fetchFromWeb(urlQuotes + this.deleteItemIDs + "/" + this.isForceDeletedRow + "/true", 3)
              .then(response => {

                if (response.status == 200) {
                  this.hideDialogDelete();

                  this.getQuotesFromApi();
                }

              })
              .catch(() => {
                alert("Failed for process delete data");
                this.hideDialogDelete();
              }).finally(() => this.buttonLoading = false);

        }
      }


    },
  }
</script>

<style>
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0deg) !important;
}
</style>


